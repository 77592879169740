<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      active: isActive,
      disabled: item.disabled
    }"
  >
    <b-link v-bind="linkProps" class="d-flex align-items-center">
      <feather-icon
        :size="item.size"
        :class="'ico_' + item.size"
        :icon="item.icon || 'CircleIcon'"
      />
      <AlertIcon class="custom-icon" v-if="item.icon == 'alert_icon'" />
      <GeoIcon class="custom-icon geo-icon" v-if="item.icon == 'geo_icon'" />
      <RouteIcon class="custom-icon" v-if="item.icon == 'route_icon'" />
      <GroupIcon class="custom-icon" v-if="item.icon == 'group_icon'" />
      <OperatorIcon class="custom-icon" v-if="item.icon == 'operator_icon'" />
      <SIMIcon class="custom-icon" v-if="item.icon == 'sim_icon'" />
      <RepeatersIcon class="custom-icon" v-if="item.icon == 'repeater_icon'" />
      <ReportCreateIcon
        class="custom-icon"
        v-if="item.icon == 'ReportCreateIcon'"
      />
      <Configurator class="custom-icon"
        v-if="item.icon == 'Configurator'"/>
      <Templates class="custom-icon"
        v-if="item.icon == 'Templates'"/>
      <ReportIcon class="custom-icon" v-if="item.icon == 'ReportIcon'" />
      <DashDealerIcon
        class="custom-icon"
        v-if="item.icon == 'DashDealerIcon'"
      />
      <DashConsumerIcon
        class="custom-icon"
        v-if="item.icon == 'DashConsumerIcon'"
      />
      <MaintenanceIcon
        class="custom-icon"
        v-if="item.icon == 'MaintenanceIcon'"
      />
      
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from "@core/libs/acl";
import { BLink, BBadge } from "bootstrap-vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import useVerticalNavMenuLink from "./useVerticalNavMenuLink";
import mixinVerticalNavMenuLink from "./mixinVerticalNavMenuLink";
import GroupIcon from "@/assets/images/icons/group-icon.svg";
import AlertIcon from "@/assets/images/icons/Alerts.svg";
import OperatorIcon from "@/assets/images/icons/operators.svg";
import SIMIcon from "@/assets/images/icons/sim.svg";
import RepeatersIcon from "@/assets/images/icons/repeaters.svg";
import DashConsumerIcon from "@/assets/images/icons/DashConsumer.svg";
import DashDealerIcon from "@/assets/images/icons/DashDealer.svg";
import ReportCreateIcon from "@/assets/images/icons/report-create.svg";
import ReportIcon from "@/assets/images/icons/report.svg";
import MaintenanceIcon from "@/assets/images/icons/maintenance.svg";
import GeoIcon from "@/assets/images/icons/geo.svg";
import RouteIcon from "@/assets/images/icons/route-menu.svg";
import Configurator from "@/assets/images/icons/Configurator.svg";
import Templates from "@/assets/images/icons/Templates.svg";

export default {
  components: {
    BLink,
    BBadge,
    AlertIcon,
    GroupIcon,
    OperatorIcon,
    RepeatersIcon,
    DashDealerIcon,
    DashConsumerIcon,
    ReportCreateIcon,
    ReportIcon,
    MaintenanceIcon,
    GeoIcon,
    RouteIcon,
    SIMIcon,
    Configurator,
    Templates,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(
      props.item
    );
    const { t } = useI18nUtils();
    const { canViewVerticalNavMenuLink } = useAclUtils();

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t
    };
  }
};
</script>
<style lang="scss">
.nav-item.active svg.custom-icon,
.nav-item.active svg.custom-icon path {
  fill: #fff;
}
.nav-item.active .geo-icon path {
  stroke: #fff;
}
.nav-item svg.custom-icon,
.nav-item svg.custom-icon path {
  fill: #565360;
}
.dark-layout {
  .nav-item svg.custom-icon,
  .nav-item svg.custom-icon path {
    fill: #fff;
  }
}
.nav-item svg.custom-icon {
  height: auto !important;
  width: 20px !important;
}
</style>
